export enum CampaignPermission {
    READ = "campaign:read",
    WRITE = "campaign:write",
}

export enum LeadsPermission {
    READ = "leads:read",
    REPROCESS = "leads:reprocess",
}

export enum MSOPipelinesPermission {
    READ = "mso:pipelines:read",
    RETRY = "mso:pipelines:retry",
}

export enum MSOTasksPermission {
    READ = "mso:tasks:read",
}

export enum OrganizationPermission {
    READ = "organizations:read",
    WRITE = "organizations:write",
}

export enum UserPermission {
    READ = "user:read",
}

export type Permission =
    | CampaignPermission
    | LeadsPermission
    | MSOPipelinesPermission
    | MSOTasksPermission
    | OrganizationPermission
    | UserPermission;
