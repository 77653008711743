"use client";

import { useRouter } from "next/navigation";

import { FC, ReactNode, useEffect } from "react";

import { SessionProvider, useSession } from "next-auth/react";

const SessionHandler: FC<{ children: ReactNode }> = ({ children }) => {
    const session = useSession();
    const { push } = useRouter();

    useEffect(() => {
        if (session?.status === "unauthenticated") {
            // FIXME: signIn method throws a missingCSRF method when called from there, probably linked to this
            //  (unsolved) bug on nextauth: https://github.com/nextauthjs/next-auth/issues/11726
            //  for now, a workaround is to redirect to the generic sign in page (where user has to choose a provider
            //  manually).
            push("/api/auth/signin");
        }
    }, [session?.status, push]);

    if (session?.status !== "authenticated") {
        return (
            <div
                style={{
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100vh",
                    gap: "1rem",
                }}
            >
                <span>Loading user session...</span>
            </div>
        );
    }

    return <>{children}</>;
};

export const LayoutContexts: FC<{ children: ReactNode }> = ({ children }) => (
    <SessionProvider>
        <SessionHandler>{children}</SessionHandler>
    </SessionProvider>
);
