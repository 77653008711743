import { jwtDecode } from "jwt-decode";

import { Permission } from "@lib/auth/scopes";

type DecodedTokenPermission = {
    authorization: {
        permissions: {
            scopes: string[];
        }[];
    };
};

const getUserPermissions = async (accessToken: string | undefined): Promise<string[]> => {
    const url = `${process.env.NEXT_PUBLIC_KEYCLOAK_BASE_URL!}/token`;

    const details = {
        grant_type: "urn:ietf:params:oauth:grant-type:uma-ticket",
        audience: process.env.NEXT_PUBLIC_KEYCLOAK_CLIENT_ID!,
    };

    const formData: string = Object.entries(details)
        .map(([key, value]) => encodeURIComponent(key) + "=" + encodeURIComponent(value as string))
        .join("&");

    const response = await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
            Authorization: `Bearer ${accessToken ?? ""}`,
        },
        body: formData,
    });

    if (!response.ok) {
        const responseText = await response.text().catch((e) => `read response body: ${e}`);
        throw new Error(`get user permissions failed with status ${response.status}: ${responseText}`);
    }

    const json: { access_token: string } = await response.json();
    const decodedToken: DecodedTokenPermission = jwtDecode(json.access_token);
    const tokenPermissions = decodedToken.authorization.permissions;

    return tokenPermissions
        .filter((element) => element.scopes != null)
        .map((element) => element.scopes)
        .reduce((a, b) => a.concat(b), [] as string[]);
};

/**
 * Returns true if at least one of the permissions is present in the roles.
 *
 * If no permission is provided, the function always returns true.
 */
export const hasPermission = async (
    accessToken: string | null | undefined,
    ...permissions: Permission[]
): Promise<boolean> => {
    if (!accessToken) return false;
    if (permissions.length === 0) return true;

    const userPermissions = await getUserPermissions(accessToken);

    return userPermissions.some((role) => permissions.includes(role as Permission)) ?? false;
};
