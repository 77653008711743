import { useEffect, useState } from "react";

import { useSession } from "next-auth/react";

import { hasPermission } from "@lib/auth/permissions";
import { Permission } from "@lib/auth/scopes";

export const usePermissions = (...permissions: Permission[]) => {
    const session = useSession();

    const [ok, setOK] = useState<boolean>();

    useEffect(() => {
        hasPermission(session?.data?.accessToken, ...permissions).then(setOK);
    }, []);

    return ok;
};
